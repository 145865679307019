
#intro{
    color: white;
    height: 100vh;
    background-image: url("/./src/Images/intro.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
}

.shader{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .45);
    display: flex;
}

.intro-content{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: inherit;
    color: white;
    text-align: left;
}

.intro-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


#skills{
    display: flex;
    justify-content: space-evenly;
}

.contact-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.contact-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}