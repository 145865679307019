
.project-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.project-content{
    text-align: left;
}

.project-img{
    aspect-ratio: 1/1;
    height: auto;
    object-fit: cover;
}